<template>
  <ui-component-modal
    modalTitle="Edit invoice footer"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="saveInvoiceFooter"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <ui-text-editor v-model="mInvoice.Footer" />
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import invoiceProvider from '@/providers/invoice'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isReady: false,
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mInvoice: null,
    }
  },

  computed: {
    ...mapState('invoiceStore', ['invoice']),
  },

  created() {
    this.mInvoice = JSON.parse(JSON.stringify(this.invoice))
  },

  methods: {
    ...mapMutations('invoiceStore', ['setInvoice']),

    saveInvoiceFooter() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true

        invoiceProvider.methods
          .saveInvoiceFooter(self.invoice.Id, self.mInvoice.Footer)
          .then((response) => {
            if (response.status === 204) {
              self.isSavingSuccess = true

              self.setInvoice(self.mInvoice)

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((editor) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },
  },
}
</script>
